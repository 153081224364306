
      @use '_variables.scss' as *;
      @use '_mixins.scss' as *;
    
$imageMaxWidth: 400px;
$headerHeight: 48px;

.banner {
  width: 100vw;
  color: white;
  position: relative;
  @include dl-down {
    max-height: calc(100vh - $headerHeight);
    overflow: hidden;
  }

  .banner-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.image {
  border-radius: 12px;
  width: 100%;
}

.stack__image {
  max-width: $imageMaxWidth;
}

.prof-container {
  background-color: #eff1f5;
  max-width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  @include dl-down {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.services-section-wrapper {
  justify-content: space-between;
  @include dl-down {
    margin: 0 80px;
    border-radius: 16px;
  }
  @include sm-down {
    margin: 0 16px;
  }

  .services-content {
    max-width: 1538px;
    padding: 52px 88px;
    color: white;
    margin: 0 auto;
    @include dl-down {
      padding: 20px 16px;
    }
  }
}

.prof-content-text {
  text-align: left;
  @include dl-down {
    text-align: center;
    font-size: 16px;
  }
}

// in <Difference />
.exploreCTA {
  @include dl-down {
    display: none;
  }
}

// in <Services />
.exploreCTA__mobile {
  align-self: center;
  margin: 16px 0 32px 0;
  @include dl-up {
    display: none;
  }
}

.CTA {
  text-wrap: nowrap;
  @include dl-down {
    font-size: 16px !important;
  }
}

.banner-CTA {
  margin-top: 40px;
  @include dl-down {
    margin-top: 16px;
    font-size: 16px !important;
    line-height: 24px;
  }
}

.swiper {
  transform: none;
  width: 100vw;
}

.swiper-horizontal {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  bottom: 41.5px !important;
  @media (max-width: 1024px) {
    bottom: 16px !important;
  }
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  width: 80px;
  height: 5px;
  opacity: 0.35;
  background: var(--opacity-layer-black-300, rgba(255, 255, 255, 0.35));
  cursor: pointer;
  border-radius: 2.86px;
  z-index: 1;
}

.swiper-pagination-bullet-active {
  position: relative;
  opacity: 1;
  @keyframes tabBackground {
    from {
      width: 0%;
    }
    to {
      width: 80px;
    }
  }
  &:after {
    left: 0;
    border-radius: 2.86px;
    position: absolute;
    height: 100%;
    width: 80px;
    content: '';
    background: #ffffff;
    animation: tabBackground 5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
}

.swiper-arrow-container {
  position: absolute;
  bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 180px;
  @media (max-width: 1024px) {
    display: none;
  }
}

.subtitle-text {
  color: var(--opacity-layer-black-400, rgba(255, 255, 255, 0.6));
  text-decoration: line-through;
}
