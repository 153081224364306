
      @use '_variables.scss' as *;
      @use '_mixins.scss' as *;
    
@use 'styles/_variables.scss' as *;

.mission-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .mission-content-wrapper {
    display: flex;
    @include dl-down {
      flex-direction: column;
    }
    gap: 32px;
  }
}

.mission-card {
  color: map-get($map: $primary, $key: 100);

  .mission-card-content {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 16px 16px;
    padding: 32px 20px;
    gap: 16px;
    flex: 1;
    background-color: map-get($map: $primary, $key: 800);
    position: relative;
    .mission-card-tag {
      position: absolute;
      top: 0;
      right: 16px;
      padding: 4px 16px;
      background-color: map-get($map: $primary, $key: 500);
      border-radius: 4px;
      transform: translateY(-50%);
      @include dl-down {
        right: 8px;
      }
    }
  }
}

// Modal Styles
.founder-story-intro {
  background-color: map-get($map: $primary, $key: 800);
  color: map-get($map: $primary, $key: 100);
  padding: 40px 40px 32px 40px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  @include dl-down {
    padding: 16px;
  }

  .founder-story-intro-content {
    display: flex;
    gap: 24px;
    @include dl-down {
      flex-direction: column;
      gap: 12px;
    }
  }
}
.founder-story-content {
  padding: 0px 24px 0 40px;
  white-space: pre-line;
  max-height: 300px;
  height: 300px;
  overflow-y: scroll;
  margin: 24px 24px 40px 0;
  @include dl-down {
    padding: 0px 16px 0 20px;
    margin: 16px 12px 20px 0;
  }

  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: map-get($map: $primary, $key: 200);
    border-radius: 8px;
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: map-get($map: $primary, $key: 600);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
}

.quotation-mark {
  height: 24px;

  &__mobile {
    height: 24px;
    font-size: 32px;
    line-height: 42px;
  }
}

.product-story {
  background-color: map-get($map: $primary, $key: 100);
  padding: 40px;
  display: flex;
  flex-direction: column;
  color: map-get($map: $primary, $key: 700);
  @include dl-down {
    padding: 20px 16px;
  }
  .video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; // 16: 9 = 9 / 16
    margin-top: 40px;
    @include dl-down {
      margin-top: 24px;
    }
    .responsive-iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 12px;
      border-color: transparent;
      width: 100%;
      height: 100%;
    }
  }
}
