
      @use '_variables.scss' as *;
      @use '_mixins.scss' as *;
    
@use 'styles/_variables.scss' as *;

.card {
  background-color: white;
  border-radius: 16px;
  display: flex;
  align-self: stretch;
}

.card__news {
  width: 392px;
  padding: 40px;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  @include dl-down {
    width: 328px;
  }
  .title {
    margin-top: 24px;
  }
  .content {
    margin-top: 12px;
  }
  &:hover {
    cursor: pointer;
  }
}

.card__reviews {
  width: 294px;
  height: 360px;
  padding: 24px 24px 16px;
}

.swipeable-group-navigation {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  @include dl-down {
    display: none;
  }
}
